<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo d-flex align-items-center ">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1 mb-0">
          Starworks Prime
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        class="d-none d-lg-flex align-items-center p-5"
        lg="8"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            alt="Forgot password V2"
            fluid
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        lg="4"
      >
        <b-col
          class="px-xl-2 mx-auto"
          lg="12"
          md="6"
          sm="8"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="resetCodeSent"
                label="Reset Code"
                label-for="forgot-password-code"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Code"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-code"
                    v-model="resetCode"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-code"
                    placeholder="012345"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="resetCodeSent"
                label="New Password"
                label-for="forgot-password-new"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="forgot-password-new"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                block
                type="submit"
                variant="primary"
                v-text="resetCodeSent ? 'Reset Password' : 'Send reset link'"
              />
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login', query: $route.query}">
              <feather-icon icon="ChevronLeftIcon" />
              Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import store from '@/store/index'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Auth } from 'aws-amplify'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      resetCodeSent: false,
      resetCode: '',
      password: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            if (this.resetCodeSent) {
              // Collect confirmation code and new password, then
              Auth.forgotPasswordSubmit(this.userEmail, this.resetCode, this.password)
                .then(data => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: data === 'SUCCESS' ? 'Password reset successfully' : 'Failed to update password',
                      icon: data === 'SUCCESS' ? 'CheckIcon' : 'XIcon',
                      variant: data === 'SUCCESS' ? 'success' : 'danger',
                    },
                  })
                  if (data === 'SUCCESS') {
                    this.$router.replace({ name: 'auth-login' })
                  }
                })
                .catch(err => this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                }))
            } else {
              // Send confirmation code to user's email
              Auth.forgotPassword(this.userEmail)
                .then(({ CodeDeliveryDetails }) => {
                  this.resetCodeSent = true
                  return this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `The code has been sent on your ${CodeDeliveryDetails.AttributeName}`,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(err => this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                }))
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
